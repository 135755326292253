import { randomId } from '@/utils/global.util';

/**
 * Formats the link's object structure
 * @param {object} provider Provider object
 * @returns {object} Returns a link object
 */
export const providerLinkFactory = (provider) => ({
  link: provider?.homePage || '',
  enabled: !!provider?.homePage || provider.isNew,
  indexType: provider.isNew ? 'WEB_CRAWLING' : 'WEB_SCRAPING',
  docType: null,
  isRemovable: !provider?.homePage || provider.isNew,
  id: randomId(),
});

/**
 * Formats the provider's object structure
 * @param {object} provider Provider object
 * @returns {object} Returns a provider object
 */
export const providerFactory = (provider) => ({
  ...provider,
  enabled: true,
  links: provider.links || [providerLinkFactory(provider)],
});
