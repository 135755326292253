<template>
  <div>
    <mds-button-container right-aligned>
      <mds-button
        variation="flat"
        icon="plus"
        :text="$t(`providerLinks.addLink`)"
        type="button"
        @click="addLink"
      />
    </mds-button-container>
    <mds-table row-hover>
      <mds-thead>
        <mds-th
          :style="{
            width: '25%',
          }"
        >
          {{ $t(`providerLinks.link`) }}
        </mds-th>
        <mds-th
          :style="{
            width: '15%',
          }"
        >
          {{ $t(`providerLinks.indexType`) }}
        </mds-th>
        <mds-th
          v-if="showPressReleaseEventCalendarColumn"
          :style="{
            width: '15%',
          }"
        >
          {{ $t(`providerLinks.containerXPath`) }}
        </mds-th>
        <mds-th
          v-if="showPressReleaseEventCalendarColumn"
          :style="{
            width: '10%',
          }"
        >
          {{ $t(`providerLinks.frequency`) }}
        </mds-th>
        <mds-th
          v-if="showPressReleaseEventCalendarColumn"
          :style="{
            width: '15%',
          }"
        >
          {{ $t(`providerLinks.s3Buckets`) }}
        </mds-th>
        <mds-th
          v-if="showDocumentTypeColumn"
          :style="{
            width: '15%',
          }"
        >
          {{ $t(`providerLinks.documentType`) }}
        </mds-th>
        <mds-th
          :style="{
            width: '100px',
          }"
        >
          {{ $t(`providerLinks.status`) }}
        </mds-th>
        <mds-th
          :style="{
            width: '100px',
          }"
        >
          {{ $t(`providerLinks.actions`) }}
        </mds-th>
      </mds-thead>
      <mds-tbody>
        <mds-tr
          v-for="link in links"
          :key="link.id"
        >
          <mds-td
            :style="{
              width: '25%',
            }"
          >
            <mds-input
              v-model="link.link"
              :disabled="!!link.link && !link.isRemovable"
              required
              type="url"
              :label="$t(`providerLinks.link`)"
              hidden-label
            />
          </mds-td>
          <mds-td
            :style="{
              width: '15%',
            }"
          >
            <mds-select
              v-model="link.indexType"
              :label="$t(`providerLinks.indexType`)"
              hidden-label
              :options="[
                { text: 'Crawl', value: 'WEB_CRAWLING' },
                { text: 'Scrape', value: 'WEB_SCRAPING' },
                { text: 'Press Release', value: 'PRESS_RELEASE' },
                { text: 'Event Calendar', value: 'EVENT_CALENDAR' },
              ]"
              @change.native="handleChangeIndexType(link)"
            />
          </mds-td>
          <mds-td
            v-if="showPressReleaseEventCalendarColumn"
            :style="{
              width: '15%',
            }"
          >
            <mds-input
              v-if="isPressReleaseEventCalendarCrawling(link)"
              v-model="link.containerXPath"
              required
              :label="$t(`providerLinks.containerXPath`)"
              hidden-label
            />
          </mds-td>
          <mds-td
            v-if="showPressReleaseEventCalendarColumn"
            :style="{
              width: '10%',
            }"
          >
            <mds-select
              v-if="isPressReleaseEventCalendarCrawling(link)"
              v-model="link.frequency"
              :label="$t(`providerLinks.frequency`)"
              hidden-label
              :options="[
                { text: 'Daily', value: 'DAILY' },
                { text: 'Weekly', value: 'WEEKLY' },
                { text: 'Monthly', value: 'MONTHLY' },
                { text: 'Every four hours', value: 'EVERY_FOUR_HOURS' },
                { text: 'Every six hours', value: 'EVERY_SIX_HOURS' },
              ]"
            />
          </mds-td>
          <mds-td
            v-if="showPressReleaseEventCalendarColumn"
            :style="{
              width: '15%',
            }"
          >
            <mds-combo-box
              v-if="isPressReleaseEventCalendarCrawling(link)"
              v-model="link.s3BucketSelected"
              :label="$t(`providerLinks.s3Buckets`)"
              hidden-label
              :data-set="s3Buckets"
              @input="setS3Buckets($event, link)"
            />
          </mds-td>
          <mds-td
            v-if="showDocumentTypeColumn"
            :style="{
              width: '15%',
            }"
          >
            <mds-combo-box
              v-if="isDocumentCrawling(link)"
              v-model="link.docTypeSelected"
              :label="$t(`providerLinks.documentType`)"
              hidden-label
              :data-set="docTypes"
              @input="setDocType($event, link)"
            />
          </mds-td>
          <mds-td
            :style="{
              width: '100px',
            }"
          >
            <mds-switch
              v-model="link.enabled"
              :label="$t(`providerLinks.active`)"
            />
          </mds-td>
          <mds-td
            :style="{
              width: '100px',
            }"
          >
            <mds-button
              v-if="link.isRemovable"
              variation="icon-only"
              icon="remove"
              type="button"
              :text="$t(`providerLinks.remove`)"
              @click="removeLink(link.id)"
            />
          </mds-td>
        </mds-tr>
      </mds-tbody>
    </mds-table>
  </div>
</template>

<script>
import MdsInput from '@mds/input';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsSwitch from '@mds/switch';
import MdsSelect from '@mds/select';
import MdsComboBox from '@mds/combo-box';
import { providerLinkFactory } from '@/utils/providers.util';
import debounce from 'lodash.debounce';
import cloneDeep from 'lodash.clonedeep';
import { MODULE } from '@/constants/store.constant';
import {
  INDEX_TYPE_WEB_CRAWLING,
  INDEX_TYPE_WEB_SCRAPING,
  INDEX_TYPE_PRESS_RELEASE,
  INDEX_TYPE_EVENT_CALENDAR,
  FREQUENCY_EVERY_FOUR_HOURS,
  FREQUENCY_DAILY
} from '@/constants/provider-link.constant';

export default {
  name: 'LinksTable',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsInput,
    MdsButton,
    MdsButtonContainer,
    MdsSwitch,
    MdsSelect,
    MdsComboBox,
  },
  props: {
    providerLinks: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      links: cloneDeep(this.providerLinks),
    };
  },
  computed: {
    showPressReleaseEventCalendarColumn () {
      return this.links.some((link) => this.isPressReleaseEventCalendarCrawling(link));
    },
    showDocumentTypeColumn () {
      return this.links.some((link) => this.isDocumentCrawling(link));
    },
    docTypes () {
      return this.$store.state[MODULE.PROVIDER_LINKS].docTypes;
    },
    originalDocTypes () {
      return this.$store.state[MODULE.PROVIDER_LINKS].originalDocTypes;
    },
    s3Buckets () {
      return this.$store.state[MODULE.PROVIDER_LINKS].s3Buckets;
    },
    originalS3Buckets () {
      return this.$store.state[MODULE.PROVIDER_LINKS].originalS3Buckets;
    },
  },
  watch: {
    links: {
      handler: debounce(function (links) {
        this.$emit('update', links);
      }, 500),
      deep: true,
    },
  },
  mounted () {
    this.getDocsTypes();
    this.getS3Buckets();
  },
  methods: {
    async getDocsTypes () {
      await this.$store.dispatch(`${MODULE.PROVIDER_LINKS}/fetchDocTypes`);
      this.links = this.links.map((currentLink) => {
        const docType = currentLink.docType ? this.docTypes.find((docType) => docType.value === currentLink.docType.id) : null;
        return ({
          ...currentLink,
          docTypeSelected: docType ? [docType.value] : null,
        });
      });
    },
    async getS3Buckets () {
      await this.$store.dispatch(`${MODULE.PROVIDER_LINKS}/fetchS3Buckets`);
      this.links = this.links.map((currentLink) => {
        const s3Bucket = currentLink.prEcS3ConfigId ? this.s3Buckets.find((s3Bucket) => s3Bucket.value === currentLink.prEcS3ConfigId) : null;
        return ({
          ...currentLink,
          s3BucketSelected: s3Bucket ? [s3Bucket.value] : null,
        });
      });
    },
    setDocType (event, link) {
      const objectDocType = this.originalDocTypes.find((docType) => docType.id === event[0]);
      this.links = this.links.map((currentLink) => {
        return ((link.id === currentLink.id) ? {
          ...currentLink,
          docType: objectDocType,
        } : { ...currentLink });
      });
    },
    setS3Buckets (event, link) {
      const objectS3Bucket = this.originalS3Buckets.find((s3Bucket) => s3Bucket.id === event[0]);
      this.links = this.links.map((currentLink) => {
        return ((link.id === currentLink.id) ? {
          ...currentLink,
          prEcS3ConfigId: objectS3Bucket.id,
        } : { ...currentLink });
      });
    },
    addLink () {
      const hasLinks = this.links.every(({ link }) => link);
      if (!hasLinks) {
        return;
      };
      const newLink = providerLinkFactory({ isNew: true });

      this.links = [...this.links, newLink];
    },
    removeLink (linkId) {
      const filteredLinks = this.links.filter(({ id }) => id !== linkId);
      if (!filteredLinks.length) {
        return;
      };

      this.links = filteredLinks;
    },
    isPressReleaseEventCalendarCrawling (link) {
      return link.indexType === INDEX_TYPE_PRESS_RELEASE || link.indexType === INDEX_TYPE_EVENT_CALENDAR;
    },
    isDocumentCrawling (link) {
      return link.indexType === INDEX_TYPE_WEB_CRAWLING || link.indexType === INDEX_TYPE_WEB_SCRAPING;
    },
    handleChangeIndexType (link) {
      if (this.isDocumentCrawling(link)) {
        link = {
          ...link,
          containerXPath: null,
          frequency: null,
          s3BucketSelected: null,
        };
      } else if (this.isPressReleaseEventCalendarCrawling(link)) {
        link = {
          ...link,
          docTypeSelected: null,
          frequency: link.indexType === INDEX_TYPE_PRESS_RELEASE ? FREQUENCY_EVERY_FOUR_HOURS : FREQUENCY_DAILY,
          s3BucketSelected: link.indexType === INDEX_TYPE_PRESS_RELEASE ? [this.s3Buckets[0].value] : [this.s3Buckets[1].value],
        };
        const objectS3Bucket = this.originalS3Buckets.find((s3Bucket) => s3Bucket.id === link.s3BucketSelected[0]);
        link.prEcS3ConfigId = objectS3Bucket.id;
      }

      const index = this.links.findIndex((l) => l.id === link.id);
      if (index !== -1) {
        this.$set(this.links, index, link);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep .mds-combo-box__result-list__dap-crawler {
  position: initial;
}
</style>
