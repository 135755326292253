var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('mds-button-container',{attrs:{"right-aligned":""}},[_c('mds-button',{attrs:{"variation":"flat","icon":"plus","text":_vm.$t(`providerLinks.addLink`),"type":"button"},on:{"click":_vm.addLink}})],1),_c('mds-table',{attrs:{"row-hover":""}},[_c('mds-thead',[_c('mds-th',{style:({
          width: '25%',
        })},[_vm._v(" "+_vm._s(_vm.$t(`providerLinks.link`))+" ")]),_c('mds-th',{style:({
          width: '15%',
        })},[_vm._v(" "+_vm._s(_vm.$t(`providerLinks.indexType`))+" ")]),(_vm.showPressReleaseEventCalendarColumn)?_c('mds-th',{style:({
          width: '15%',
        })},[_vm._v(" "+_vm._s(_vm.$t(`providerLinks.containerXPath`))+" ")]):_vm._e(),(_vm.showPressReleaseEventCalendarColumn)?_c('mds-th',{style:({
          width: '10%',
        })},[_vm._v(" "+_vm._s(_vm.$t(`providerLinks.frequency`))+" ")]):_vm._e(),(_vm.showPressReleaseEventCalendarColumn)?_c('mds-th',{style:({
          width: '15%',
        })},[_vm._v(" "+_vm._s(_vm.$t(`providerLinks.s3Buckets`))+" ")]):_vm._e(),(_vm.showDocumentTypeColumn)?_c('mds-th',{style:({
          width: '15%',
        })},[_vm._v(" "+_vm._s(_vm.$t(`providerLinks.documentType`))+" ")]):_vm._e(),_c('mds-th',{style:({
          width: '100px',
        })},[_vm._v(" "+_vm._s(_vm.$t(`providerLinks.status`))+" ")]),_c('mds-th',{style:({
          width: '100px',
        })},[_vm._v(" "+_vm._s(_vm.$t(`providerLinks.actions`))+" ")])],1),_c('mds-tbody',_vm._l((_vm.links),function(link){return _c('mds-tr',{key:link.id},[_c('mds-td',{style:({
            width: '25%',
          })},[_c('mds-input',{attrs:{"disabled":!!link.link && !link.isRemovable,"required":"","type":"url","label":_vm.$t(`providerLinks.link`),"hidden-label":""},model:{value:(link.link),callback:function ($$v) {_vm.$set(link, "link", $$v)},expression:"link.link"}})],1),_c('mds-td',{style:({
            width: '15%',
          })},[_c('mds-select',{attrs:{"label":_vm.$t(`providerLinks.indexType`),"hidden-label":"","options":[
              { text: 'Crawl', value: 'WEB_CRAWLING' },
              { text: 'Scrape', value: 'WEB_SCRAPING' },
              { text: 'Press Release', value: 'PRESS_RELEASE' },
              { text: 'Event Calendar', value: 'EVENT_CALENDAR' },
            ]},nativeOn:{"change":function($event){return _vm.handleChangeIndexType(link)}},model:{value:(link.indexType),callback:function ($$v) {_vm.$set(link, "indexType", $$v)},expression:"link.indexType"}})],1),(_vm.showPressReleaseEventCalendarColumn)?_c('mds-td',{style:({
            width: '15%',
          })},[(_vm.isPressReleaseEventCalendarCrawling(link))?_c('mds-input',{attrs:{"required":"","label":_vm.$t(`providerLinks.containerXPath`),"hidden-label":""},model:{value:(link.containerXPath),callback:function ($$v) {_vm.$set(link, "containerXPath", $$v)},expression:"link.containerXPath"}}):_vm._e()],1):_vm._e(),(_vm.showPressReleaseEventCalendarColumn)?_c('mds-td',{style:({
            width: '10%',
          })},[(_vm.isPressReleaseEventCalendarCrawling(link))?_c('mds-select',{attrs:{"label":_vm.$t(`providerLinks.frequency`),"hidden-label":"","options":[
              { text: 'Daily', value: 'DAILY' },
              { text: 'Weekly', value: 'WEEKLY' },
              { text: 'Monthly', value: 'MONTHLY' },
              { text: 'Every four hours', value: 'EVERY_FOUR_HOURS' },
              { text: 'Every six hours', value: 'EVERY_SIX_HOURS' },
            ]},model:{value:(link.frequency),callback:function ($$v) {_vm.$set(link, "frequency", $$v)},expression:"link.frequency"}}):_vm._e()],1):_vm._e(),(_vm.showPressReleaseEventCalendarColumn)?_c('mds-td',{style:({
            width: '15%',
          })},[(_vm.isPressReleaseEventCalendarCrawling(link))?_c('mds-combo-box',{attrs:{"label":_vm.$t(`providerLinks.s3Buckets`),"hidden-label":"","data-set":_vm.s3Buckets},on:{"input":function($event){return _vm.setS3Buckets($event, link)}},model:{value:(link.s3BucketSelected),callback:function ($$v) {_vm.$set(link, "s3BucketSelected", $$v)},expression:"link.s3BucketSelected"}}):_vm._e()],1):_vm._e(),(_vm.showDocumentTypeColumn)?_c('mds-td',{style:({
            width: '15%',
          })},[(_vm.isDocumentCrawling(link))?_c('mds-combo-box',{attrs:{"label":_vm.$t(`providerLinks.documentType`),"hidden-label":"","data-set":_vm.docTypes},on:{"input":function($event){return _vm.setDocType($event, link)}},model:{value:(link.docTypeSelected),callback:function ($$v) {_vm.$set(link, "docTypeSelected", $$v)},expression:"link.docTypeSelected"}}):_vm._e()],1):_vm._e(),_c('mds-td',{style:({
            width: '100px',
          })},[_c('mds-switch',{attrs:{"label":_vm.$t(`providerLinks.active`)},model:{value:(link.enabled),callback:function ($$v) {_vm.$set(link, "enabled", $$v)},expression:"link.enabled"}})],1),_c('mds-td',{style:({
            width: '100px',
          })},[(link.isRemovable)?_c('mds-button',{attrs:{"variation":"icon-only","icon":"remove","type":"button","text":_vm.$t(`providerLinks.remove`)},on:{"click":function($event){return _vm.removeLink(link.id)}}}):_vm._e()],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }