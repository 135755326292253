<template>
  <mds-row>
    <mds-col>
      <mds-search-field
        v-model="searchedValue"
        placeholder="Search by Name or Company Id"
        label="Search Company"
        @input.native="searchValue"
        @blur="areResultsVisible = false"
      />
      <mds-search-results
        v-if="areResultsVisible"
        width="500px"
        class="search__results"
      >
        <mds-list-group>
          <mds-list-group-item
            v-for="company in companies"
            :key="company.companyId"
            :text="company.translatedName"
            :metadata-below="[company.companyId, company.country, company.legalName]"
            @mousedown.native.prevent="selectCompany(company); areResultsVisible = false"
          />
        </mds-list-group>
      </mds-search-results>
    </mds-col>
  </mds-row>
</template>

<script>
import MdsSearchField from '@mds/search-field';
import { MdsListGroup, MdsListGroupItem } from '@mds/list-group';
import { MdsSearchResults } from '@mds/search-results';
import { COMMON, MODULE } from '@/constants/store.constant';
import { getCompanies } from '@/services/api/company.service';
import { providerFactory } from '@/utils/providers.util';

import debounce from 'lodash.debounce';

export default {
  name: 'SearchCompany',
  components: {
    MdsSearchField,
    MdsSearchResults,
    MdsListGroup,
    MdsListGroupItem,
  },
  data () {
    return {
      companies: [],
      searchedValue: '',
      areResultsVisible: false,
    };
  },
  methods: {
    selectCompany (company) {
      const formattedCompany = providerFactory(company);
      this.$store.commit(`${MODULE.PROVIDER}/${COMMON.UPDATE_STATE}`, { name: 'provider', value: formattedCompany });
    },
    searchValue: debounce(async function ({ target: { value: nameOrCompanyId } }) {
      const { data: { result: companies } } = await getCompanies({ nameOrCompanyId });
      this.companies = companies;
      this.areResultsVisible = true;
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.search {
  &__results {
    margin-top: $mds-space-1-x;
  }
}
</style>
