<template>
  <mds-layout-grid>
    <title-section />
    <search-company v-if="!$route.params.id" />
    <form-section />
  </mds-layout-grid>
</template>

<script>
import TitleSection from '@/components/provider/title/TitleSection';
import SearchCompany from '@/components/provider/search/SearchCompany';
import FormSection from '@/components/provider/form/FormSection';

export default {
  name: 'ProviderPage',
  components: {
    TitleSection,
    SearchCompany,
    FormSection,
  },
};
</script>
