<template>
  <mds-form @submit.native.prevent="saveProvider">
    <mds-fieldset legend="Status">
      <mds-switch
        v-model="provider.enabled"
        label="Active"
      />
    </mds-fieldset>
    <mds-fieldset horizontal>
      <mds-input
        v-model="provider.translatedName"
        label="Name"
        disabled
      />
      <mds-input
        v-model="provider.companyId"
        label="Company Id"
        disabled
      />
    </mds-fieldset>
    <mds-fieldset horizontal>
      <mds-input
        v-model="provider.homePage"
        label="Homepage"
        disabled
      />
      <mds-input
        v-model="provider.country"
        label="Country"
        disabled
      />
    </mds-fieldset>
    <links-table
      :provider-links="provider.links"
      @update="updateLinks"
    />
    <mds-button-container right-aligned>
      <mds-button
        variation="primary"
        type="submit"
        text="Save"
      />
    </mds-button-container>
  </mds-form>
</template>

<script>
import MdsForm from '@mds/form';
import MdsFieldset from '@mds/fieldset';
import MdsSwitch from '@mds/switch';
import MdsInput from '@mds/input';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import cloneDeep from 'lodash.clonedeep';
import LinksTable from '@/components/provider/form/links/LinksTable';
import { postProvider, putProvider } from '@/services/api/provider.service';
import ROUTE from '@/constants/routes.constant';

export default {
  name: 'ProviderForm',
  components: {
    MdsForm,
    MdsFieldset,
    MdsSwitch,
    MdsInput,
    MdsButton,
    MdsButtonContainer,
    LinksTable,
  },
  props: {
    providers: {
      type: Array,
      default: () => [],
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      provider: {},
    };
  },
  watch: {
    providers: {
      handler ([provider]) {
        this.provider = cloneDeep(provider);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateLinks (links) {
      this.provider.links = [...links];
    },
    async saveProvider () {
      const saveProvider = this.isNew ? postProvider : putProvider;
      const { data: updatedProvider } = await saveProvider(this.provider);
      this.isNew && this.$router.push({ name: ROUTE.PROVIDER, params: { id: updatedProvider.companyId } });

      this.provider = updatedProvider;
    },
  },
};
</script>
