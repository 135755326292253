import http from '@/plugins/http';
import ENDPOINTS from '@/constants/endpoints.constant';

/**
 * @param {object} params Search and pagination information
 * @param {string} params.nameOrCompanyId Company id or name to look for
 * @param {number} [params.skipRows=0] Amount of companies per page
 * @param {number} [params.countRows=5] Page number
 * @returns {promise} Promise object containing companies in Morningstar's database
 */
export const getCompanies = ({ nameOrCompanyId, skipRows = 0, countRows = 5 }) => http.get(ENDPOINTS.COMPANY, { params: { nameOrCompanyId, countRows, skipRows } });
