<template>
  <mds-row>
    <store-provider
      v-bind="$options.storeInfo"
      :params="{ companyId: $route.params.id }"
    >
      <template v-slot="{ state: providers }">
        <provider-form
          :providers="providers"
          :is-new="!$route.params.id"
        />
      </template>
    </store-provider>
  </mds-row>
</template>

<script>
import StoreProvider from '@/components/common/StoreProvider';
import ProviderForm from '@/components/provider/form/info/ProviderForm';
import { COMMON, MODULE } from '@/constants/store.constant';

export default {
  name: 'FormSection',
  components: {
    StoreProvider,
    ProviderForm,
  },
  storeInfo: {
    module: MODULE.PROVIDER,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
};
</script>
